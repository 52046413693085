import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { IForecastSalaryFormValues, ISalaryForecast } from "../../../interface/salariesForecast";
import SalariesInfosFields from "./SalariesInfosField";
import SalariesMonthFields from "./SalariesMonthFields";
import getBusinessDaysForMonth from "../../../tools/getBusinessDays";

const validationSchema = yup.object({
  collab: yup.string(),
  fictifName: yup.string(),
  agency: yup.string().required("L'agence est obligatoire"),
  contract: yup.string().required("Le contract est obligatoire")
});

const AddUpdateSalaries = ({
  salaryId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  setShowConfirmDialog
}: {
  salaryId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[],
  setShowConfirmDialog: Function
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [salary, setSalary] = useState<ISalaryForecast>();

  const getSalary = async () => {
    if (!token || !salaryId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/salaries/${salaryId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSalary(syncResponse.data.message);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getSalary();
  }, []);

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      collab: salary && salary.collab ? salary.collab._id : "",
      fictifName: salary && salary.fictifName ? salary.fictifName : "",
      agency: salary ? salary.agency : "",
      contract: salary ? salary.contract : "",
      months: salary ? salary.months
        : monthList.map((month) => {
          return {
            label: month.field,
            brutSalary: 0,
            openDays: getBusinessDaysForMonth(parseInt(month.field.slice(month.field.length - 4, month.field.length)), month.field.slice(0, month.field.length - 4)),
            presenceTime: 100,
            bonus: 0,
            fees: 0,
            saving: 0
          }
        }),
    },
    validationSchema: validationSchema,
    onSubmit: (values: IForecastSalaryFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, salaryId);
    },
    enableReinitialize: true,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <SalariesInfosFields salaryName={salary?.collab ? (salary?.collab.firstname + " " + salary?.collab.lastname) : salary?.fictifName} formik={formik} />
        <SalariesMonthFields formik={formik} monthList={monthList} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => { setShowConfirmDialog(true) }}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
    </Box>
  )
}

export default AddUpdateSalaries;