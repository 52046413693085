import { InputAdornment, Switch, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react";

const SalariesMonthFields = ({
	formik,
	monthList
}: {
	formik: any,
	monthList: { name: string, field: string }[],
}) => {

	console.log("formik => ", formik);
	//console.log("monthList => ", monthList);

	const label = { inputProps: { 'aria-label': 'Switch demo' } };

	const [typeChecked, setTypeChecked] = useState<boolean>(false);

	const [brutAmount, setBrutAmount] = useState<number>(0);
	const [presenceAmount, setPresenceAmount] = useState<number>(100);
	const [bonusAmount, setBonusAmount] = useState<number>(0);
	const [feesAmount, setFeesAmount] = useState<number>(0);
	const [savingAmount, setSavingAmount] = useState<number>(0);

	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newAmount = parseFloat(event.target.value);
		const name = event.target.name;

		switch (name) {
			case "brutSalary":
				setBrutAmount(newAmount)
				break;
			case "presenceTime":
				setPresenceAmount(newAmount)
				break;
			case "bonus":
				setBonusAmount(newAmount)
				break;
			case "fees":
				setFeesAmount(newAmount)
				break;
			case "saving":
				setSavingAmount(newAmount)
				break;
			default:
				console.error(`Catégorie ${name} inconnue !`)
		}
		updateMonthsToConstant(newAmount, name);
	};

	const updateMonthsToConstant = (amount: number, type: string) => {
		monthList.forEach((key, index) => {
			formik.setFieldValue(`months[${index}][${type}]`, amount);
		});
	};

	const handleMonthChange = (e: any) => {
		const { name, value } = e.target;
		const field = name.split("-")[0];
		const index = name.split("-")[1];
		formik.setFieldValue(`months[${index}][${field}]`, value === "" ? "" : Number(value));
	};

	const monthFields = [
		{ id: "brutSalary", label: "Salaire brut", symbol: "€", fixedValue: brutAmount },
		{ id: "openDays", label: "Nb Jours ouvrés", symbol: "", fixedValue: null },
		{ id: "presenceTime", label: "Temps présence", symbol: "%", fixedValue: presenceAmount },
		{ id: "bonus", label: "Primes / Variables", symbol: "€", fixedValue: bonusAmount },
		{ id: "fees", label: "Frais divers", symbol: "€", fixedValue: feesAmount },
		{ id: "saving", label: "Epargne", symbol: "€", fixedValue: savingAmount },
	]

	return (
		<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "60%", height: "700px", overflow: "scroll" }}>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Typography variant="h6" sx={{ textAlign: "center", fontWeight: !typeChecked ? "bold" : "" }}>Montants constants</Typography>
				<Switch
					{...label}
					onClick={() => {
						const newTypeChecked = !typeChecked;
						setTypeChecked(newTypeChecked);

						if (!newTypeChecked) {
							updateMonthsToConstant(brutAmount, "brutSalary");
						}
					}}
					checked={typeChecked || false}
				/>
				<Typography variant="h6" sx={{ textAlign: "center", fontWeight: typeChecked ? "bold" : "" }}>Montants variables</Typography>
			</Box>
			{!typeChecked && (
				<Box sx={{ display: "flex" }}>
					{
						monthFields.map((field) => {
							if (field.id !== "openDays") {
								return (
									<TextField
										margin="normal"
										required
										type="number"
										inputProps={{
											step: "0.1",
											inputMode: "decimal"
										}}
										id={field.id}
										label={field.label + " mensuel"}
										name={field.id}
										autoComplete={field.id}
										value={field.fixedValue}
										onChange={handleAmountChange}
										autoFocus
										sx={{ width: "19%", marginInline: "auto" }}
										InputProps={{
											endAdornment: <InputAdornment position="end">{field.symbol}</InputAdornment>,
										}}
									/>
								)
							}
						})
					}
				</Box>
			)}
			{
				monthList.map((month: any, index) => {
					return (
						<>
							<Typography variant="subtitle1" sx={{ textAlign: 'center' }}>-- {(month.name).toUpperCase()} --</Typography>
							<Box sx={{ display: "flex" }}>
								{
									monthFields.map((field) => {
										// console.log("field affiché", field, formik.values.months[index][field.id]);
										//console.log("field affiché", field);

										return (
											<TextField
												margin="normal"
												required
												type="number"
												inputProps={{
													step: "0.5",
													inputMode: "decimal"
												}}
												id={field.id}
												label={field.label}
												name={`${field.id}-${index}`}
												autoComplete={field.id}
												value={formik.values.months[index][field.id]}
												onChange={handleMonthChange}
												autoFocus
												sx={{ width: "15%", marginInline: "auto" }}
												InputProps={{
													endAdornment: <InputAdornment position="end">{field.symbol}</InputAdornment>,
												}}
												disabled={!typeChecked && field.id !== "openDays"}
											/>
										)
									})
								}
							</Box>
						</>
					)
				})
			}
		</Box>
	)
}

export default SalariesMonthFields;