import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import getSubcontractData from "../../../tools/forecast/getSubcontractData";
import { ISubcontract } from "../../../interface/subcontract";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { Typography } from "@mui/material";

const COLUMNS_TO_DISPLAY = {
    infos: [
        { name: "Libellé", field: "label" },
        { name: "Agence", field: "agency" },
        { name: "Fournisseur", field: "supplier" }
    ]
};

    

const SubcontractData = ({
    forecastVersion,
    forecastDates,
    agency,
    selectedSubcontract,
    setSelectedSubcontract,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal,
    type,
    subcontracts
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    agency: string | null,
    selectedSubcontract: string[],
    setSelectedSubcontract: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setEditId: Function,
    setOpenModal: Function,
    type: string,
    subcontracts : ISubcontract[]
}) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const params = useParams();

    const monthList = getForecastMonthList(forecastDates);

  



    const columnsInfos: GridColDef[] = useMemo(() => COLUMNS_TO_DISPLAY.infos.map((column) => ({
        field: column.field,
        headerName: column.name,
        filterable: true,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
        headerClassName: "custom-header",
        renderCell: (params) => (
            <div style={{ whiteSpace: 'normal' }}>
                {params.value?.split('\n').map((line: any, index: any) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </div>
        ),
    })), []);
    
    const columnsMonth: GridColDef[] = useMemo(() => monthList.map((column) => ({
        field: column.field,
        headerName: column.name,
        minWidth: 160,
        headerClassName: "custom-header",
    })), [monthList]);
    
    const columns: GridColDef[] = useMemo(() => [...columnsInfos, ...columnsMonth], [columnsInfos, columnsMonth]); // Éviter le recalcul inutile
    
   


    const turnoverRows: GridRowsProp = useMemo(() => subcontracts.map((subcontract) => {
        const turnoverRow: any = {
            id: subcontract._id,
            label: `\n${subcontract.label}\n`,
            agency: `\n${subcontract.agency}\n`,
            supplier: `\n${subcontract.supplier.name}\n`
        };
    
        let monthExpenses = Object.fromEntries(
            Object.entries(subcontract.months).map(([key, value]) => {
                if (typeof value === "object" && value !== null) {
                    const obj = value as { label: string; value: number };
                    return [obj.label, `\n${obj.value} €\n`];
                }
                return [key, `\n${value} €\n`];
            })
        );
    
        return { ...turnoverRow, ...monthExpenses };
    }), [subcontracts]); 
    


    const totalByMonth = useMemo(() => monthList.reduce((acc: any, item: any) => {
        acc[item.field] = subcontracts.reduce((sum, subcontract) => {
            const monthData = subcontract.months.find((month: any) => month.label === item.field);
            return sum + (monthData ? monthData.value : 0);
        }, 0);
        return acc;
    }, {}), [monthList, subcontracts]); // Ne se met à jour que si `subcontracts` change
    

    useEffect(() => {
        dispatch(setTotalByMonth(`Sous-traitance-${type}`, totalByMonth));
    }, [totalByMonth, dispatch]); // Ne se met à jour que si `totalByMonth` change
    

    const totalRow = {
        id: "total", // Une ID unique pour cette ligne
        label: `\nTOTAL\n`,
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `\n${value} €\n`])
        )
    };

    const rows: GridRowsProp = subcontracts.length > 0 ? [...turnoverRows, totalRow] : [...turnoverRows];

    return (
        <>
            <Typography variant="h5" sx={{ textAlign: "center", backgroundColor: "black", color: "white" }}>{type}</Typography>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                sx={{
                    '& .MuiDataGrid-columnHeader .sticky-column': {
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                    },
                    '& .sticky-column': {
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    maxHeight: "75vh",
                }}
                checkboxSelection
                rowSelectionModel={selectedSubcontract}
                onRowSelectionModelChange={(selectionModel) => { setSelectedSubcontract(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}

            />
        </>
    )
}

export default SubcontractData;